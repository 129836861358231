<template>
  <div>
    <add-hot-stu />
  </div>
</template>
<script>
import AddHotStu from './components/AddHotStu'
export default {
  name:"",
  components:{
    AddHotStu
  }
}
</script>